import { toast } from "react-toastify";
import { callApi } from "../../API/api";
import Button from "../../components/buttons/Buttons";
import { useStateContext } from "../../context/StateContext";
import SkeletonLoading from "../../components/skeleton/SkeletonLoading";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import ChangePayoutCard from "../../components/ChangePayoutCard/ChangePayoutCard";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";

const ChangeReward = () => {
  const [payOutId, setPayoutId] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [payoutsData, setPayoutsData] = useState([]);
  const [changedItems, setChangedItems] = useState([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const { isLoading, setIsLoading, isSubmitting, setIsSubmitting } = useStateContext();

  useEffect(() => {
    if (payoutsData && payoutsData.length > 0) {
      const allFieldsValid = payoutsData.every(
        item =>
          item.reward !== "" &&
          item.goal?.minimum !== "" &&
          item.goal?.maximum !== "" &&
          item.goal?.rem !== "" &&
          item.goal?.deep !== ""
      );
      setAllFieldsFilled(allFieldsValid);
    }
  }, [payoutsData]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await callApi("/goals/get-reward-goals", "GET");
      if (response.success) {
        const goalsData = response.data;
        const payoutId = response.data._id;
        delete goalsData._id;
        delete goalsData.createdAt;
        delete goalsData.updatedAt;
        const formattedData = Object.keys(goalsData).map((key) => ({
          id: key,
          ...goalsData[key],
        }));
        setPayoutId(payoutId);
        setPayoutsData(formattedData);
      }
    } catch (error) {
      toast.error(error.response?.data?.message ?? error?.message);
    }
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEditClick = () => {
    setIsEditable(true);
  };

  const handleCancel = () => {
    setIsEditable(false);
    setChangedItems([]);
    fetchData();
  };

  const handleMouseOver = () => {
    if (!allFieldsFilled) {
      setShowTooltip(true);
    }
  };

  const handleMouseOut = () => {
    setShowTooltip(false);
  };


  const handleSave = async () => {
    setIsLoading(true);
    setIsSubmitting(true);
    try {
      const payload = {
        id: payOutId,
        ...payoutsData.reduce((acc, item) => {
          acc[item.id] = { reward: item.reward, goal: item.goal, name: item.name, image: item.image };
          return acc;
        }, {})
      };
      const response = await callApi("/goals/update-reward-goals", "POST", payload);
      if (response.success) {
        setChangedItems([]);
        setIsEditable(false);
        toast.success("Data Updated Successfully");
      } else {
        toast.error("Error: ", response.error.message);
      }
    } catch (error) {
      toast.error(error.response?.data?.message ?? error?.message);
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const handleRewardChange = useCallback(
    (id, newReward) => {
      setPayoutsData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, reward: newReward } : item
        )
      );
      if (!changedItems.includes(id)) {
        setChangedItems((prevItems) => [...prevItems, id]);
      }
    },
    [changedItems]
  );

  const handleGoalsChange = useCallback(
    (id, newGoal) => {
      setPayoutsData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, goal: newGoal } : item
        )
      );
      if (!changedItems.includes(id)) {
        setChangedItems((prevItems) => [...prevItems, id]);
      }
    },
    [changedItems]
  );

  const memoizedPayoutsData = useMemo(() => payoutsData, [payoutsData]);
  const memoizedIsEditable = useMemo(() => isEditable, [isEditable]);

  return (
    <div className="flex flex-col gap-5 w-full">
      {/* Heading */}
      <div>
        <StyledH3Heading
          fontColor="text-black pl-5"
          children={"Change Daily Payouts"}
        />
      </div>
      <div className="w-full shadow-md bg-white py-5 px-4 md:px-8 rounded-2xl flex flex-col gap-5 pb-10 items-end">
        <div className="h-8"> {!memoizedIsEditable && <EditButton onClick={handleEditClick} />}</div>
        {isLoading ? (
          <SkeletonLoading type="reward" />
        ) : (
          <div className="flex flex-col gap-7 items-center sm:items-end">
            <div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
              {memoizedPayoutsData.map((item) => (
                <div key={item.id}>
                  <ChangePayoutCard
                    key={item.id}
                    item={item}
                    isEditable={memoizedIsEditable}
                    onRewardChange={handleRewardChange}
                    onGoalsChange={handleGoalsChange}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        {memoizedIsEditable && (
          <div className="w-full flex md:justify-end justify-center">
            <div className="w-[100%] md:max-w-[40%] flex md:flex-row flex-col md:justify-end justify-center items-center gap-2 relative">
              <Button
                className="w-[100%] px-14"
                label="Cancel"
                variant="cancel"
                fontColor="text-light-gray"
                onClick={handleCancel}
              />
              <Button
                label={isSubmitting ? "Saving" : "Save"}
                variant="save"
                onClick={handleSave}
                fontColor={"text-black"}
                className={`rounded-full w-[100%] px-14 ${isSubmitting || !allFieldsFilled
                  ? "bg-darker-yellow opacity-80 cursor-not-allowed"
                  : "bg-yellow hover:bg-dark-yellow active:bg-darker-yellow"
                  }`}
                disabled={isSubmitting || !allFieldsFilled}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              />

              {/* Tooltip */}
              {showTooltip && !allFieldsFilled && (
                <div className="absolute bottom-[60px] right-0 w-full lg:w-1/2 px-4 py-2 bg-white text-red text-lg text-center rounded-lg shadow-lg z-50">
                  Please fill all the above fields
                </div>
              )}
            </div>

          </div>
        )}
      </div>
    </div>
  );
};

const EditButton = ({ onClick }) => (
  <div onClick={onClick} className="flex items-center gap-1 cursor-pointer">
    <img src="/assets/editpen.svg" alt="Edit" />
    <h6 className="text-yellow">Edit</h6>
  </div>
);

export default ChangeReward;
