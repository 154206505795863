import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Files Import
import CommonCard from "../../components/commonCard/CommonCard";
import StyledHeading from "../../common/styledHeading/StyledHeading";
import StyledXsText from "../../common/components/styledXsText/styledXsText";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledH5Heading from "../../common/components/styledh5heading/StyledH5Heading";
import { toast } from "react-toastify";
import { callApi } from "../../API/api";
import BlogDetailSkeleton from "../../components/skeleton/BlogDetailSkeleton";
import { useStateContext } from "../../context/StateContext";


const SingleBlogSection = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const imageURL = process.env.REACT_APP_SERVER_ROOT;
  const { isLoading, setIsLoading } = useStateContext()

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        setIsLoading(true)
        const response = await callApi("/blogs/get-blog-by-id", "POST", { id });
        if (response.success) {
          setBlog(response.data);
          setIsLoading(false)
        } else {
          toast.error("Error fetching blog: " + response.message);
        }
      } catch (error) {
        toast.error(error.response?.data?.message ?? error?.message);
      }
    };

    fetchBlog();
  }, [id]);

  return (
    <CommonCard>
      {isLoading ? (<BlogDetailSkeleton />) : (<div className="flex flex-col gap-5 pt-5 w-full ">
        {/* Heading */}
        <div>
          <StyledH5Heading
            children={"Preview"}
            fontColor={"text-black font-semibold pl-10"}
          />
        </div>
        {/* Blog Detail Start */}
        <div className="w-full px-5 sm:px-10 lg:px-20 xl:px-40 pb-20 ">
          {/* Blog Image */}
          <div className="w-full flex flex-col justify-center items-center">
            <img
              src={`${imageURL}/uploads/blogImages/${blog?.image}`}
              alt="Blog"
              className="w-full h-full rounded-[20px]"
            />
          </div>
          <div className="w-full flex flex-col justify-center items-start gap-2">
            <div className="w-full flex justify-between items-center pt-6">
              {/* Use the blog's category */}
              <StyledXsText
                children={blog?.categoryId?.name}
                fontColor={"text-light-gray"}
              />
              <StyledXsText children={blog?.authorId.name} fontColor={"text-light-gray"} />
            </div>
            {/* Use the blog's title as heading */}
            <div className="w-full flex flex-col justify-center items-start gap-3">
              <StyledHeading
                children={blog?.title}
                fontColor={"text-black font-extrabold leading-[30px] "}
              />
              {/* Use the blog's body as content */}
              <StyledMdText fontColor={"text-dark-gray leading-[170%]"}>
                <div dangerouslySetInnerHTML={{ __html: blog?.body }} />
              </StyledMdText>
            </div>
          </div>
        </div>
      </div>)}
    </CommonCard>
  );
};

export default SingleBlogSection;
