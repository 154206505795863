import React, { useState } from "react";
import CommonCard from "../commonCard/CommonCard";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledH5Heading from "../../common/components/styledh5heading/StyledH5Heading";

const ChangePayoutCard = ({
  item,
  isEditable,
  onGoalsChange,
  onRewardChange,
}) => {
  const { id, image, name, goal, reward } = item;

  const inputClassName =
    "bg-[#F9F9F9] text-black text-md w-full rounded-[30px] py-3 pl-6 outline-none";

  const [rem, setRem] = useState(goal.rem);
  const [deep, setDeep] = useState(goal.deep);
  const [localGoal, setLocalGoal] = useState(goal);
  const [rewardState, setRewardState] = useState(reward);
  const [minimumGoal, setMinimumGoal] = useState(goal.minimum);
  const [maximumGoal, setMaximumGoal] = useState(goal.maximum);

  const handleRewardChange = (e) => {
    const newReward = e.target.value;
    setRewardState(newReward);
    onRewardChange(id, newReward);
  };

  const handleMinimumGoalChange = (e) => {
    const newMinimumGoal = e.target.value;
    setMinimumGoal(newMinimumGoal);
    onGoalsChange(id, { ...goal, minimum: newMinimumGoal });
  };

  const handleMaximumGoalChange = (e) => {
    const newMaximumGoal = e.target.value;
    setMaximumGoal(newMaximumGoal);
    onGoalsChange(id, { ...goal, maximum: newMaximumGoal });
  };

  const handleRemChange = (e) => {
    const newRem = e.target.value;
    setRem(newRem);
    onGoalsChange(id, { ...goal, rem: newRem });
  };

  const handleDeepChange = (e) => {
    const newDeep = e.target.value;
    setDeep(newDeep);
    onGoalsChange(id, { ...goal, deep: newDeep });
  };

  const handleRewardChangeForSteps = (index, newReward) => {
    const updatedGoal = localGoal.map((step, i) =>
      i === index ? { ...step, reward: newReward } : step
    );
    setLocalGoal(updatedGoal);
    onRewardChange(id, updatedGoal);
  };

  const handleCountChangeForSteps = (index, newCount) => {
    const updatedGoal = localGoal.map((step, i) =>
      i === index ? { ...step, count: newCount } : step
    );
    setLocalGoal(updatedGoal);
    onGoalsChange(id, updatedGoal);
  };

  return (
    <CommonCard
      width={
        "hover:shadow-lg transition-all duration-100 h-full self-stretch w-full"
      }
    >
      <div className="w-full flex flex-col justify-center items-center px-10 sm:px-5 md:px-10 py-4">
        <div className="w-full h-full flex flex-col justify-center items-center gap-6 border-b-2 border-[#F9F9F9] py-6">
          <img
            className="h-[50px] w-[50px]"
            src={`${process.env.REACT_APP_SERVER_ROOT}/static/icons/${image}`}
            alt={name}
          />
          <StyledH5Heading fontColor="text-black">
            {name ? name : "--"}
          </StyledH5Heading>
        </div>
        <div className="w-full py-4">
          {id === "steps" ? (
            <div className="w-full">
              <div className="w-[80%] flex justify-between">
                <StyledMdText fontColor="text-black font-semibold">Reward</StyledMdText>
                <StyledMdText fontColor="text-black font-semibold">Steps</StyledMdText>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2">
                {localGoal.map((stepGoal, index) => (
                  <React.Fragment key={index}>
                    <input
                      className={`${inputClassName} ${stepGoal.reward ? "" : "border border-red"}`}
                      type="text"
                      value={`${!isEditable ? `${stepGoal.reward} FITS` : stepGoal.reward}`}
                      onChange={(e) => handleRewardChangeForSteps(index, e.target.value)}
                      readOnly={!isEditable}
                    />
                    <input
                      className={`${inputClassName} ${stepGoal.count ? "" : "border border-red"}`}
                      type="text"
                      value={stepGoal.count}
                      onChange={(e) => handleCountChangeForSteps(index, e.target.value)}
                      readOnly={!isEditable}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          ) : id === "activeMinutes" ? (
            <>
              <div className="w-full my-4 flex flex-col justify-center items-start gap-2">
                <StyledMdText fontColor="text-black font-semibold">Reward</StyledMdText>
                <input
                  className={`${inputClassName} ${rewardState === "" ? "border border-red" : ""}`}
                  type="text"
                  value={`${!isEditable ? `${rewardState} FITS` : rewardState}`}
                  onChange={handleRewardChange}
                  readOnly={!isEditable}
                />
              </div>
              <div className="w-full my-4 flex flex-col justify-center items-start gap-2">
                <StyledMdText fontColor="text-black font-semibold">Number of Days</StyledMdText>
                <input
                  className={`${inputClassName} ${goal?.days ? "" : "border border-red"}`}
                  type="text"
                  value={goal?.days ?? 0}
                  onChange={(e) => onGoalsChange(id, { ...goal, days: e.target.value })}
                  readOnly={!isEditable}
                />
              </div>
              <div className="w-full my-4 flex flex-col justify-center items-start gap-2">
                <StyledMdText fontColor="text-black font-semibold">Active Minutes</StyledMdText>
                <input
                  className={`${inputClassName} ${goal.minutes ? "" : "border border-red"}`}
                  type="text"
                  value={goal?.minutes ?? 0}

                  onChange={(e) => onGoalsChange(id, { ...goal, minutes: e.target.value })}
                  readOnly={!isEditable}
                />
              </div>
            </>
          ) : id === "sleep" ? (
            <>
              <div className="w-full my-4 flex flex-col justify-center items-start gap-2">
                <StyledMdText fontColor="text-black font-semibold">Reward</StyledMdText>
                <input
                  className={`${inputClassName} ${rewardState === "" ? "border border-red" : ""}`}
                  type="text"
                  value={`${!isEditable ? `${rewardState} FITS` : rewardState}`}
                  onChange={handleRewardChange}
                  readOnly={!isEditable}
                />
              </div>
              <div className="w-full my-4 flex flex-col justify-center items-start gap-2">
                <StyledMdText fontColor="text-black font-semibold">Goals (Minimum hrs)</StyledMdText>
                <input
                  className={`${inputClassName} ${minimumGoal === "" ? "border border-red" : ""}`}
                  type="text"
                  value={goal?.minimum ?? 0}
                  onChange={handleMinimumGoalChange}
                  readOnly={!isEditable}
                />
              </div>
              <div className="flex gap-4">
                <div className="w-full my-4 flex flex-col justify-center items-start gap-2">
                  <StyledMdText fontColor="text-black font-semibold">REM %</StyledMdText>
                  <input
                    className={`${inputClassName} ${rem === "" ? "border border-red" : ""}`}
                    type="text"
                    value={rem ?? 0}
                    onChange={handleRemChange}
                    readOnly={!isEditable}
                  />
                </div>
                <div className="w-full my-4 flex flex-col justify-center items-start gap-2">
                  <StyledMdText fontColor="text-black font-semibold">Deep Sleep %</StyledMdText>
                  <input
                    className={`${inputClassName} ${deep === "" ? "border border-red" : ""}`}
                    type="text"
                    value={deep ?? 0}
                    onChange={handleDeepChange}
                    readOnly={!isEditable}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full my-4 flex flex-col justify-center items-start gap-2">
                <StyledMdText fontColor="text-black font-semibold">Reward</StyledMdText>
                <input
                  className={`${inputClassName} ${rewardState === "" ? "border border-red" : ""}`}
                  type="text"
                  value={`${!isEditable ? `${rewardState} FITS` : rewardState}`}
                  onChange={handleRewardChange}
                  readOnly={!isEditable}
                />
              </div>
              <div className="w-full flex flex-col justify-center items-start gap-2">
                <StyledMdText fontColor="text-black font-semibold">
                  {id === "weight"
                    ? "Goals (BMI)"
                    : id === "bodyTemperature"
                      ? "Goals (C°)"
                      : id === "breathingRate"
                        ? "Goals (bpm)"
                        : "Goals"}
                </StyledMdText>
                <div className="w-full flex justify-start items-center gap-2">
                  {id === "weight" ? (
                    <input
                      className={`${inputClassName} text-gray-500 ${isEditable ? "cursor-not-allowed" : ''}`}
                      type="text"
                      value="Healthy BMI"
                      disabled
                    />
                  ) :
                    id === "hrv" ? (
                      <input
                        className={`${inputClassName} text-gray-500 ${isEditable ? "cursor-not-allowed" : ''}`}
                        type="text"
                        value="Declines with age"
                        disabled
                      />
                    ) : (
                      <div className="w-full flex justify-center items-center gap-2">
                        <input
                          className={`${inputClassName} ${minimumGoal ? "" : "border border-red"}`}
                          type="text"
                          value={minimumGoal ?? 0}
                          onChange={handleMinimumGoalChange}
                          readOnly={!isEditable}
                        />
                        <StyledMdText fontColor="text-light-gray">To</StyledMdText>
                        <input
                          className={`${inputClassName} ${maximumGoal === "" ? "border border-red" : ""}`}
                          type="text"
                          value={maximumGoal ?? 0}
                          onChange={handleMaximumGoalChange}
                          readOnly={!isEditable}
                        />
                      </div>
                    )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </CommonCard>
  );
};

export default ChangePayoutCard;
