import React, { useState, useEffect } from "react";
import Button from "../../components/buttons/Buttons";
import CommonCard from "../../components/commonCard/CommonCard";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { callApi } from "../../API/api";
import { getUserData, setUserData } from "../../utils/localStorage";
import { validTypes } from "../newBlogSection/NewBlogSection";
import { UserContext } from "../../context/userContext/UserContext";

const ProfileSection = () => {
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState({
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const [uploadedImage, setUploadedImage] = useState(null);
  const { profile, setProfile } = UserContext();

  const togglePasswordVisibility = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };


  const profileValidationSchema = yup.object({
    name: yup.string().required("Username is required"),
    password: yup.string().required("Current password is required"),
    newPassword: yup.string().min(6, "New Password must have at least 6 characters"),
    confirmPassword: yup.string().oneOf([yup.ref("newPassword")], "Passwords must match"),
    image: yup.mixed().nullable()
  });

  const formik = useFormik({
    initialValues: {
      image: null,
      name: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: profileValidationSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("image", values.image);
        formData.append("password", values.password);
        formData.append("newPassword", values.newPassword);
        formData.append("confirmPassword", values.confirmPassword);
        const response = await callApi("/auth/update-profile", "POST", formData,);
        if (response.status === 200) {
          setUserData(response.data);
          setProfile(response.data)
          toast.success(response?.message);
          navigate("/");
        }
      } catch (error) {
        toast.error(error.response?.data?.message ?? error?.message);
        if (error.name === "ValidationError") {
          const errors = {};
          toast.inner.forEach((err) => {
            errors[err.path] = err.message;
          });
          setErrors(errors);
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (!validTypes.includes(file.type)) {
      toast.error('Please select an image, allowed file types are jpg, jpeg, png & webp.');
      return false;
    }
    if (file) {
      formik.setFieldValue("image", file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    const userData = getUserData();
    if (userData) {
      formik.setValues({
        name: userData.name || "",
        email: userData.email || "",
        password: "",
        newPassword: "",
        confirmPassword: "",
      });
      setUploadedImage(profile.profileUrl || null);
    }
  }, []);

  return (
    <div className="flex flex-col items-center w-full gap-5">
      <div className="w-full">
        <StyledH3Heading children="Profile" fontColor="text-black pl-5" />
      </div>
      <div className="flex flex-col items-center justify-center w-full">
        <CommonCard width="w-full sm:w-[70%] lg:w-[60%] xl:w-[46%]">
          <div className="flex flex-col items-center justify-center w-full gap-10 p-6">
            <div className="relative">
              <img
                className="w-32 h-32 object-cover outline outline-yellow outline-offset-[3px] outline-2 rounded-full"
                src={uploadedImage}
                alt="Profile"
                name="image"
              />
              <label
                htmlFor="image-upload"
                className="absolute right-0 w-12 h-12 cursor-pointer top-24"
              >
                <img
                  className="w-full h-full"
                  src="/assets/profile/cam.svg"
                  alt="Camera"
                />
                <input
                  name="image"
                  id="image-upload"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
            <form className="flex flex-col items-start justify-center w-full gap-4 sm:px-4" onSubmit={formik.handleSubmit}>
              <label className="flex flex-col w-full gap-2">
                <StyledMdText children="Username" fontColor="text-black font-semibold" />
                <input
                  className="bg-[#F9F9F9] text-xs w-full rounded-[30px] py-4 px-6 outline-none hover:border-gray-300 focus:border-gray-300 pr-10"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  placeholder="Username"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-xs text-red">{formik.errors.name}</div>
                )}
              </label>
              <label className="flex flex-col w-full gap-2">
                <StyledMdText children="Email" fontColor="text-black font-semibold" />
                <input
                  className="bg-[#F9F9F9] text-xs w-full rounded-[30px] py-4 px-6 outline-none hover:border-gray-300 focus:border-gray-300 pr-10"
                  type="text"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  placeholder="Email"
                  disabled
                />
              </label>
              {["password", "newPassword", "confirmPassword"].map((field, index) => {
                const label = field.replace(/([A-Z])/g, " $1").replace(/^./, str => str.toUpperCase());
                const showField = `show${label.replace(" ", "")}`;
                return (
                  <label key={index} className="relative flex flex-col w-full gap-2">
                    <StyledMdText children={label} fontColor="text-black font-semibold" />
                    <input
                      aria-label={label}
                      className="bg-[#F9F9F9] text-xs w-full rounded-[30px] py-4 px-6 outline-none hover:border-gray-300 focus:border-gray-300 pr-10"
                      type={passwordVisibility[showField] ? "text" : "password"}
                      name={field}
                      value={formik.values[field]}
                      onChange={formik.handleChange}
                      placeholder={label}
                    />
                    {formik.touched[field] && formik.errors[field] && (
                      <div className="text-xs text-red">{formik.errors[field]}</div>
                    )}
                    <button
                      type="button"
                      onClick={() => togglePasswordVisibility(showField)}
                      className="absolute flex items-center text-gray-700 top-11 right-3"
                    >
                      <img
                        src={`/assets/icons/${passwordVisibility[showField] ? "open-eye" : "close-eye"}.svg`}
                        alt={`${passwordVisibility[showField] ? "Open" : "Close"} eye`}
                      />
                    </button>
                  </label>
                );
              })}
              <div className="flex flex-col items-center justify-between w-full gap-3 md:flex-row">
                <Button
                  label="Cancel"
                  variant="cancel"
                  fontColor="text-light-gray"
                  onClick={() => navigate("/")}
                />
                <Button
                  label={formik.isSubmitting ? "Saving" : "Save Changes"}
                  variant="save"
                  fontColor="text-white"
                  className={`rounded-full w-full ${formik.isSubmitting
                    ? "bg-darker-yellow opacity-80 cursor-not-allowed"
                    : "bg-yellow hover:bg-dark-yellow active:bg-darker-yellow"
                    }`}
                  type="submit"
                  disabled={formik.isSubmitting}
                />
              </div>
            </form>
          </div>
        </CommonCard>
      </div>
    </div>
  );
};

export default ProfileSection;
