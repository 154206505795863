import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { callApi } from '../../API/api';
import StyledMdText from '../../common/components/styledMdText/styledMdText';
import StyledCtaText from '../../common/components/styledCtaText/styledCtaText';
import StyledH3Heading from '../../common/components/styledH3Heading/StyledH3Heading';
import AuthenticationLayout from '../../components/AuthenticationLayout/AuthenticationLayout';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStateContext } from '../../context/StateContext';

const NewPassSection = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { isSubmitting, setIsSubmitting } = useStateContext();


  if (!token) {
    navigate('/login');
  }

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(8, 'Password must be at least 8 characters')
        .required('New password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm password is required'),
    }),
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        const response = await callApi('/auth/change-password', 'POST', {
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
          token,
        });
        if (response.success) {
          toast.success('Password has been set successfully');
          navigate('/login');
        } else {
          toast.error(response.message || 'Failed to set new password');
        }
      } catch (error) {
        toast.error(error.response?.data?.message ?? error?.message);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <AuthenticationLayout>
      <div className="w-full flex flex-col gap-10 px-5 md:px-20">
        {/* Heading */}
        <div className="w-full flex flex-col items-center text-center gap-1 sm:gap-0">
          <StyledH3Heading
            children={"Enter New Password"}
            fontColor={"text-black"}
          />
          <StyledMdText
            children={"Set the new password so you can login to your account"}
            fontColor={"text-light-gray w-11/12 sm:w-1/2"}
          />
        </div>

        {/* Form */}
        <form onSubmit={formik.handleSubmit} className="w-full flex flex-col justify-center items-center gap-5">
          {/* New Password Input */}
          <div className="relative w-full">
            <input
              name="newPassword"
              type={showPassword ? "text" : "password"}
              placeholder="New Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPassword}
              className="border border-light-gray rounded-xl outline-none w-full py-4 px-4 hover:border-gray-300 focus:border-gray-300 pr-10"
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="absolute top-5 right-3"
            >
              <img
                src={`/assets/icons/${showPassword ? "open-eye" : "close-eye"
                  }.svg`}
                alt={showPassword ? "open eye" : "close eye"}
              />
            </button>
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className="text-red">{formik.errors.newPassword}</div>
            )}
          </div>

          {/* Confirm Password Input */}
          <div className="relative w-full">
            <input
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm Password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              className="border border-light-gray rounded-xl outline-none w-full py-4 px-4 hover:border-gray-300 focus:border-gray-300 pr-10"
            />
            <button
              type="button"
              onClick={toggleConfirmPasswordVisibility}
              className="absolute top-5 right-3"
            >
              <img
                src={`/assets/icons/${showConfirmPassword ? "open-eye" : "close-eye"
                  }.svg`}
                alt={showConfirmPassword ? "open eye" : "close eye"}
              />
            </button>
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className="text-red">{formik.errors.confirmPassword}</div>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-yellow rounded-full w-full py-4 hover:bg-dark-yellow focus:bg-darker-yellow"
          >
            <StyledCtaText
              children={isSubmitting ? "Submitting" : "Submit"}
              fontColor={"text-black font-semibold"}
              className={`rounded-full w-full py-4 ${isSubmitting
                ? "bg-darker-yellow opacity-80 cursor-not-allowed"
                : "bg-yellow hover:bg-dark-yellow active:bg-darker-yellow"
                }`}
              disabled={isSubmitting}
            />

          </button>
        </form>
      </div>
    </AuthenticationLayout>
  );
};

export default NewPassSection;
