import React from "react";
import StyledLgText from "../../common/components/styledLgText/styledLgText";

const CheckBox = ({ defaultChecked = true, onChange, value, type }) => {

  return (
    <label className="inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        value={value}
        className="sr-only peer"
        checked={defaultChecked}
        onChange={onChange}
      />
      <div className="relative w-11 h-6 bg-gray-300 rounded-full peer-focus:outline-none peer-focus:ring-[#FBF2CC] peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-[#FBF2CC] after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-yellow after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#FBF2CC]"></div>
      <StyledLgText fontColor="text-black ms-3 font-semibold">
        {type === "blog" ? defaultChecked ? "Published" : "Unpublished" : defaultChecked ? "Active" : "Inactive"}
      </StyledLgText>
    </label>
  );
};

export default CheckBox;