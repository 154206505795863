import * as yup from "yup";
import { useFormik } from "formik";
import RTE from "../../components/RTE";
import { toast } from "react-toastify";
import { callApi } from "../../API/api";
import { cleanSlug } from "../../helpers/helpers";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Buttons";
import CheckBox from "../../components/checkBox/CheckBox";
import DropDown from "../../components/dropDown/DropDown";
import React, { useState, useRef, useEffect } from "react";
import InputField from "../../components/InputField/InputField";
import CommonCard from "../../components/commonCard/CommonCard";
import StyledXlText from "../../common/components/styledXlText/styledXlText";
import StyledMdText from "../../common/components/styledMdText/styledMdText";

export const validTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']; // Image Validation

const NewBlogSection = () => {
  const inputRef = useRef();
  const navigate = useNavigate()
  const [coverImage, setCoverImage] = useState("");
  const [blogCategory, setBlogCategory] = useState([]);
  useEffect(() => {
    // Fetch categories when the component mounts
    const fetchCategories = async () => {
      try {
        const response = await callApi("blogs/get-all-categories", "GET");
        setBlogCategory(response.data);
      } catch (error) {
        toast.error(error.response?.data?.message ?? error?.message);
      }
    };

    fetchCategories();
  }, []);

  const blogSchema = yup.object().shape({
    slug: yup.string().required("Slug is required"),
    body: yup.string().required("Body is required"),
    title: yup.string().required("Title is required"),
    image: yup.mixed().required("Blog Image is required"),
    categoryId: yup.string().required("Category is required"),
    metaDescription: yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      body: "",
      image: null,
      title: "",
      metaTitle: "",
      categoryId: "",
      isActive: true,
      metaKeywords: "",
      metaDescription: "",
    },
    validationSchema: blogSchema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      try {
        setSubmitting(true)
        const formData = new FormData();
        Object.keys(values).map((item) => {
          formData.append(item, values[item]);
        })

        const response = await callApi("/blogs/create-new-blog", "POST", formData, {}, true);
        if (response.success) {
          toast.success("Blog is added successfully!");
          navigate("/blogs")
        }
      } catch (error) {
        toast.error(error.response?.data?.message ?? error.message);
        if (error.name === "ValidationError") {
          const errors = {};
          toast.inner.forEach((err) => {
            errors[err.path] = err.message;
          });
          setErrors(errors);
        } else {
          toast.error(error?.message);
        }
      }
      finally {
        setSubmitting(false);
      }
    },
  });

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    formik.setFieldValue("image", file)
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBrowse = (event) => {
    const file = event.target.files[0];
    if (!validTypes.includes(file.type)) {
      toast.error('Please select an image, allowed file types are jpg, jpeg, png & webp.');
      return false;
    }
    if (file) {
      formik.setFieldValue("image", file)
      const reader = new FileReader();
      reader.onloadend = () => {
        setCoverImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRemoveImage = () => {
    setCoverImage(null);
    inputRef.current.value = null;
    formik.setFieldValue("image", null)
  };

  return (
    <div className="flex flex-col items-center justify-center w-full gap-4">
      <form className="w-full" onSubmit={formik.handleSubmit}>

        <div
          className="flex flex-col items-center justify-center w-full gap-4"
          onDrop={handleDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          <CommonCard width={"w-full"}>
            <div className="flex flex-col w-full gap-3 px-5 py-5 md:px-10 sm:pb-10">
              <div>
                <StyledXlText
                  children={"Cover"}
                  fontColor={"text-black font-semibold pl-1 sm:pl-5"}
                />
              </div>
              <div
                className="flex flex-col justify-center items-center h-[527px] w-full border border-dashed border-[#f5e7ae] py-24 gap-4 bg-[#F9F9F9] overflow-hidden rounded-[22px]"
                style={{
                  position: "relative",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
              >

                {coverImage && <img
                  className="absolute top-0 left-0 object-cover w-full h-full transition-opacity duration-300 hover:bg-black hover:bg-opacity-50 "
                  src={coverImage}
                  alt="CoverImage"
                />}


                {coverImage && <button
                  className="absolute w-6 bg-white rounded-full top-4 right-4"
                  onClick={handleRemoveImage}
                >
                  <img src="/assets/cancel.svg" alt="Remove" className="w-full" />
                </button>}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={((e) => handleBrowse(e, formik.setFieldValue))}
                  ref={inputRef}
                />
                <img
                  className="max-w-10"
                  src="/assets/blog/Vector.svg"
                  alt=""
                  onClick={() => inputRef.current.click()}
                />
                <StyledXlText
                  children={"Drag & Drop Files Here"}
                  fontColor={"text-light-gray"}
                />
                <StyledMdText children={"Or"} fontColor={"text-light-gray"} />
                <div className="w-[55%] max-w-48 ">
                  <Button
                    label="Browse"
                    variant="save"
                    fontColor="text-black"
                    onClick={() => inputRef.current.click()}
                  />
                </div>
              </div>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={((e) => handleBrowse(e, formik.setFieldValue))}
                ref={inputRef}
              />
              {formik.touched.image && formik.errors.image && (
                <div className="text-red text-xs text-left ml-2 mt-2">{formik.errors.image}</div>
              )}
            </div>
          </CommonCard>

          <div className="w-full">
            <CommonCard>
              <div className="w-full px-5 py-10 sm:px-10">
                <div className="flex flex-col items-center justify-center w-full gap-6">
                  <div className="w-full">
                    <InputField
                      label="Heading"
                      name="title"
                      value={formik.values.title}
                      onChange={(e) => {
                        formik.setFieldValue('title', e.target.value)
                        formik.setFieldValue('slug', cleanSlug(e.target.value))
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.title && formik.errors.title && (
                      <div className="text-red text-xs text-left ml-2 mt-2">{formik.errors.title}</div>
                    )}
                  </div>
                  <div className="w-full">
                    <InputField
                      name="slug"
                      label="Slug"
                      value={formik.values.slug || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.slug && formik.errors.slug && (
                      <div className="text-red text-xs text-left ml-2 mt-2">{formik.errors.slug}</div>
                    )}
                  </div>
                  <div className="w-full">
                    <InputField
                      name="metaDescription"
                      label="Description"
                      value={formik.values.metaDescription || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.metaDescription && formik.errors.metaDescription && (
                      <div className="text-red text-xs text-left ml-2 mt-2">{formik.errors.metaDescription}</div>
                    )}
                  </div>
                  <div className="w-full">
                    <div className="w-full pb-2">
                      <StyledMdText
                        children={"Paragraph"}
                        fontColor={"text-black font-semibold"}
                      />
                    </div>
                    <div className="w-full text-light-gray ">
                      <RTE
                        name="body"
                        value={formik.values.body}
                        onEditorChange={(newValue) => {
                          formik.setFieldValue("body", newValue);
                        }}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {formik.touched.body && formik.errors.body && (
                      <div className="text-red text-xs text-left ml-2 mt-2">{formik.errors.body}</div>
                    )}
                  </div>
                  <div className="w-full">
                    <DropDown
                      label="Blog Category"
                      options={blogCategory.map((category) => ({
                        value: category._id,
                        label: category.name,
                      }))}
                      name="categoryId"
                      value={formik.values.categoryId}
                      onChange={(e) =>
                        formik.setFieldValue("categoryId", e.target.value)
                      }
                    />
                    {formik.touched.categoryId && formik.errors.categoryId && (
                      <div className="text-red text-xs text-left ml-2 mt-2">{formik.errors.categoryId}</div>
                    )}
                  </div>
                  <InputField
                    name="metaTitle"
                    label="Meta Title"
                    value={formik.values.metaTitle || ""}
                    onChange={formik.handleChange}
                  />
                  <InputField
                    name="metaDescription"
                    label="Meta Description"
                    value={formik.values.metaDescription || ""}
                    onChange={formik.handleChange}
                  />
                  <InputField
                    name="metaKeywords"
                    label="Meta Keywords"
                    value={formik.values.metaKeywords || ""}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </CommonCard>
          </div>
          <div className="flex flex-col items-end justify-between w-full gap-5 sm:flex-row sm:items-center">
            <div>
              <CheckBox
                type="blog"
                name="isActive"
                children={"Publish"}
                defaultChecked={formik.values.isActive}
                onChange={() =>
                  formik.setFieldValue("isActive", !formik.values.isActive)
                }
              />
            </div>
            <div className="flex flex-col justify-center w-full gap-2 sm:flex-row sm:w-fit">
              <Link to={"/blogs"} className="w-[100%] md:max-w-40">
                <Button
                  className="w-full px-14"
                  label="Cancel"
                  variant="cancel"
                  fontColor="text-light-gray"
                />
              </Link>
              <Button
                label="Create"
                variant="save"
                fontColor="text-black"
                disabled={formik.isSubmitting}
                className={`w-[100%] px-14 ${formik.isSubmitting
                  ? "bg-darker-yellow opacity-80 cursor-not-allowed"
                  : "bg-yellow hover:bg-dark-yellow active:bg-darker-yellow"
                  }`}
                type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewBlogSection;
