import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { callApi } from "../../API/api";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledCtaText from "../../common/components/styledCtaText/styledCtaText";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import AuthenticationLayout from "../../components/AuthenticationLayout/AuthenticationLayout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useStateContext } from "../../context/StateContext";

const ForgotPassSection = () => {
  const navigate = useNavigate();
  const { isSubmitting, setIsSubmitting } = useStateContext();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        const response = await callApi("auth/change-email", "POST", {
          email: values.email,
        });
        if (response.status === 200) {
          if (response.success) {
            sessionStorage.setItem("email", values.email);
            toast.success(response.message);
            navigate("/verify-email");
          } else {
            toast.error(response.message);
          }
        }
      } catch (error) {
        toast.error(error.response?.data?.message ?? error?.message);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <AuthenticationLayout>
      <div className="w-full flex flex-col gap-10 px-5 md:px-20">
        {/* Heading */}
        <div className="w-full flex flex-col items-center text-center gap-1 sm:gap-0">
          <StyledH3Heading children={"Forgot Password"} fontColor={"text-black"} />
          <StyledMdText
            children={
              "Enter your email address we will send you a link to reset your password."
            }
            fontColor={"text-light-gray md:w-[60%]"}
            className=""
          />
        </div>

        {/* Form */}
        <form className="flex flex-col gap-7" onSubmit={formik.handleSubmit}>
          {/* Email Input */}
          <div>
            <input
              aria-label="Email Address"
              className="text-sm border border-gray-400 rounded-xl outline-none w-full py-4 px-4 hover:border-gray-300 focus:border-gray-300"
              type="email"
              placeholder="Email Address"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="text-red text-sm">{formik.errors.email}</div>
            ) : null}
          </div>

          {/* Submit Button */}
          <div>
            <button
              type="submit"
              className={`rounded-full w-full py-4 ${isSubmitting
                ? "bg-darker-yellow opacity-80 cursor-not-allowed"
                : "bg-yellow hover:bg-dark-yellow active:bg-darker-yellow"
                }`}
              disabled={isSubmitting}
            >
              <StyledCtaText children={isSubmitting ? "Submitting" : "Submit"} fontColor={"text-black font-semibold"} />
            </button>
          </div>

          {/* Back to Login */}
          <div className="w-full flex justify-center">
            <Link
              to="/login"
              className="text-black flex justify-center items-center gap-4 transition-all duration-300 cursor-pointer hover:gap-2 w-fit"
            >
              <img src="/assets/icons/leftarrow.svg" alt="LeftArrow" />
              <StyledMdText children={"Back to Login"} fontColor={"text-black font-semibold"} />
            </Link>
          </div>
        </form>
      </div>
    </AuthenticationLayout>
  );
};

export default ForgotPassSection;
