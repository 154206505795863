import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { useStateContext } from "../../../context/StateContext";
import StyledMdBoldText from "../../../common/components/styledMdBoldText/styledMdBoldText";
import { UserContext } from "../../../context/userContext/UserContext";

const SideBarItem = ({ to, icon, label, isActive, onClick, position }) => {
  const topBorderRadius = position === "above" ? "lg:rounded-br-[18px]" : "";
  const bottomBorderRadius = position === "below" ? "lg:rounded-tr-[18px]" : "";
  const slidingItemBgColor = isActive ? "bg-white" : "bg-yellow";

  return (
    <Link
      to={to}
      className={`relative w-full flex justify-start items-center py-4 cursor-pointer ${topBorderRadius} ${bottomBorderRadius} ${slidingItemBgColor} ${
        isActive ? "" : "hover:bg-dark-yellow active:bg-darker-yellow"
      }`}
      onClick={() => {
        onClick(to);
      }}
    >
      {isActive && (
        <motion.div
          layoutId="activeBackground"
          initial={false}
          animate={{ y: 0 }}
          transition={{ type: "spring", duration: 0.6 }}
          className="absolute inset-0 bg-white "
        />
      )}
      {isActive && (
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.4 }}
          className="w-1 h-8 rounded-2xl bg-yellow absolute left-3"
        ></motion.div>
      )}
      <div className="flex items-center gap-4 px-10 w-full z-10">
        <img src={icon} alt={`${label}-icon`} className="min-w-5 lg:w-4" />
        <StyledMdBoldText
          children={label}
          fontColor={isActive ? "text-black" : "text-black"}
        />
      </div>
    </Link>
  );
};

const SideBar = () => {
  const location = useLocation();
  const { isModalOpen, setIsModalOpen } = useStateContext();
  const { profile } = UserContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(location.pathname);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    document.body.classList.toggle("overflow-hidden", isSidebarOpen);
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isSidebarOpen]);

  const handleItemClick = (path) => {
    setActiveLink(path);
    setIsSidebarOpen(false);
  };

  const isActiveLink = (path) => {
    const isActive =
      location.pathname === path ||
      location.pathname.startsWith(path + "/") ||
      (path === "/blogs" &&
        (location.pathname.startsWith("/blogs/") ||
          location.pathname.startsWith("/edit-blog")));
    return isActive;
  };

  const getPosition = (index) => {
    if (isActiveLink(sidebarItems[index].to)) {
      return "active";
    }
    if (index > 0 && isActiveLink(sidebarItems[index - 1].to)) {
      return "below";
    }
    if (
      index < sidebarItems.length - 1 &&
      isActiveLink(sidebarItems[index + 1].to)
    ) {
      return "above";
    }
    return "none";
  };

  const sidebarItems = [
    {
      to: "/dashboard",
      icon: "/assets/icons/navbar/dashboard.svg",
      label: "Dashboard",
    },
    {
      to: "/user-details",
      icon: "/assets/icons/navbar/users.svg",
      label: "User Details",
    },
    {
      to: "/change-payouts",
      icon: "/assets/icons/navbar/reward.svg",
      label: "Daily Payouts",
    },
    {
      to: "/tokens",
      icon: "/assets/icons/navbar/token.svg",
      label: "KPI of Tokens",
    },
    { to: "/blogs", icon: "/assets/icons/navbar/blog.svg", label: "Blogs" },
    {
      to: "/blogs-categories",
      icon: "/assets/icons/navbar/blog-category.svg",
      label: "Blogs Categories",
    },
  ];

  return (
    <div className="lg:w-[25%] xl:w-[20%] lg:fixed h-auto overflow-y-auto no-scrollbar z-40">
      {/* Hamburger Menu Button */}
      <div className="lg:hidden absolute bg-white drop-shadow-md flex justify-between items-center w-full h-[55px] px-10">
        {/* Logo */}
        <Link to={"/"} onClick={() => setIsSidebarOpen(false)}>
          <img
            src="/assets/logo/navlogo.svg"
            alt="Open Menu"
            className="w-full"
          />
        </Link>
        <button onClick={() => setIsSidebarOpen(true)}>
          <img src="/assets/hamburger.svg" alt="Open Menu" className="w-6" />
        </button>
      </div>

      {/* Sidebar */}
      <nav
        className={`fixed top-0 left-0 h-screen bg-yellow flex flex-col items-center pt-3 pb-6 transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 w-[300px] max-w-[500px] lg:w-full lg:static z-40`}
      >
        {/* Logo and User Profile */}
        <div className="w-full flex flex-col justify-center items-center gap-3 px-4 h-fit">
          <Link
            to={"/"}
            className="w-full hidden lg:flex"
            onClick={() => setIsSidebarOpen(false)}
          >
            <div className=" bg-white w-full lg:flex justify-center items-center rounded-xl py-1.5">
              <img
                className="max-w-60"
                src="/assets/logo/navlogo.svg"
                alt="logo"
              />
            </div>
          </Link>
          <div className="w-full flex flex-col justify-end items-end gap-4">
            {/* Close Button for Small Screens */}
            <button
              onClick={() => setIsSidebarOpen(false)}
              className="lg:hidden w-7"
            >
              <img src="/assets/cancel.svg" alt="Close Menu" />
            </button>
            <Link
              to="/profile"
              className="w-full flex justify-center"
              onClick={() => setIsSidebarOpen(false)}
            >
              <div className="w-full bg-dull-yellow hover:bg-darkest-yellow border border-white flex justify-center items-center gap-3 rounded-xl py-1.5 px-6">
                <img
                  src={
                    profile?.profile
                      ? `${process.env.REACT_APP_SERVER_ROOT}/static/images/${profile?.profile}`
                      : "/assets/profile.svg"
                  }
                  alt="profile"
                  className="rounded-full w-[40px] h-[40px]"
                />
                <StyledMdBoldText
                  children={profile?.name ?? "Debbie Schoen"}
                  fontColor={"text-white"}
                />
              </div>
            </Link>
          </div>
        </div>

        {/* Sidebar Navigation Items */}
        <div className="w-full mt-6 bg-white h-auto max-h-[60%] overflow-y-auto no-scrollbar">
          {sidebarItems.map((item, index) => (
            <SideBarItem
              key={item.to}
              to={item.to}
              icon={item.icon}
              label={item.label}
              isActive={isActiveLink(item.to)}
              onClick={() => handleItemClick(item.to)}
              position={getPosition(index)}
            />
          ))}
        </div>

        {/* Logout Button */}
        <div className="w-full px-4 flex flex-col justify-center items-center mt-auto h-fit">
          <button
            onClick={() => setIsModalOpen(!isModalOpen)}
            className="w-full bg-white hover:bg-[#FAF9F6] active:bg-[#FCF5E5] rounded-xl flex justify-start items-center px-8 gap-6 py-3.5"
          >
            <img
              src="/assets/icons/navbar/arrowleft.svg"
              alt="Logout"
              className="w-4 lg:w-3"
            />
            <StyledMdBoldText children={"Logout"} fontColor={"text-black"} />
          </button>
        </div>
      </nav>

      {/* Overlay for Small Screens */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}
    </div>
  );
};

export default SideBar;
