import React, { useState } from "react";

// Files Import
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { callApi } from "../../API/api";
import { setUserData } from "../../utils/localStorage";
import { Link, useNavigate, useLocation } from "react-router-dom";
import StyledXsText from "../../common/components/styledXsText/styledXsText";
import StyledMdText from "../../common/components/styledMdText/styledMdText";
import StyledCtaText from "../../common/components/styledCtaText/styledCtaText";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";
import AuthenticationLayout from "../../components/AuthenticationLayout/AuthenticationLayout";
import { UserContext } from "../../context/userContext/UserContext";

const LoginSection = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const redirectUrl = new URLSearchParams(location.search).get("redirect");
  const { setProfile } = UserContext();

  // Form validation schema using Yup
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a valid email to continue.")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password length is too short.")
      .required("Password is required"),
  });

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const response = await callApi("/auth/login", "POST", values);
        const { data, token, message } = response;

        if (response.status === 200) {
          if (token) {
            setUserData(data);
            setProfile(data);
            localStorage.setItem("token", token);
            toast.success(message || "Login successful");
            navigate(redirectUrl ?? "/dashboard");
          } else {
            toast.error("Token is missing");
          }
        } else {
          toast.error("Login failed");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error?.message);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    values,
    isSubmitting,
  } = formik;

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <AuthenticationLayout>
      <div className="w-full flex flex-col gap-10 px-5 md:px-20">
        {/* Heading */}
        <div className="w-full flex flex-col items-center text-center gap-1 sm:gap-0">
          <StyledH3Heading children={"Admin Login"} fontColor={"text-black"} />
          <StyledMdText
            children={"Enter your email address to login to your account"}
            fontColor={"text-light-gray w-11/12 sm:w-1/2"}
          />
        </div>
        {/* Form */}
        <form
          className="w-full flex flex-col gap-6"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="w-full flex flex-col gap-3">
            {/* Email Input */}
            <div className="w-full">
              <input
                id="email"
                aria-label="Email Address"
                className="text-sm border border-gray-400 rounded-xl outline-none w-full py-4 px-4 hover:border-gray-300 focus:border-gray-300"
                type="email"
                name="email"
                placeholder="Email Address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
              />
              {touched.email && !!errors.email && (
                <StyledXsText fontColor="text-red font-medium">
                  {errors.email}
                </StyledXsText>
              )}
            </div>
            {/* Password Input */}
            <div className="relative w-full">
              <input
                id="password"
                aria-label="Password"
                className="w-full px-4 py-4 pr-10 text-sm border border-gray-400 rounded-xl outline-none hover:border-gray-300 focus:border-gray-300"
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
              />
              {/* Show Password Button */}
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute top-5 right-3"
              >
                <img
                  src={`/assets/icons/${showPassword ? "open-eye" : "close-eye"
                    }.svg`}
                  alt={showPassword ? "open eye" : "close eye"}
                  className=""
                />
              </button>
              {touched.password && !!errors.password && (
                <StyledXsText fontColor="text-red font-medium">
                  {errors.password}
                </StyledXsText>
              )}
            </div>
            {/* Remember Me Checkbox - Forgot Password */}
            <div className="flex items-center justify-between w-full mt-1">
              {/* Remember Me Checkbox */}
              <div className="flex items-center gap-2">
                <input
                  type="checkbox"
                  id="rememberMe"
                  className="cursor-pointer"
                  checked={values.rememberMe}
                  onChange={() =>
                    setFieldValue("rememberMe", !values.rememberMe)
                  }
                />
                <label htmlFor="rememberMe">
                  <StyledMdText
                    children={"Remember me"}
                    fontColor={"text-black font-semibold cursor-pointer"}
                  />
                </label>
              </div>
              {/* Forgot Password Link */}
              <div>
                <Link
                  to="/forgot-password"
                  className="text-black hover:underline"
                >
                  <StyledMdText
                    children={"Forgot password?"}
                    fontColor={"text-black font-semibold"}
                  />
                </Link>
              </div>
            </div>
          </div>
          {/* Submit Button */}
          <div className="w-full">
            <button
              type="submit"
              className={`rounded-full w-full py-4 ${isSubmitting
                ? "bg-darker-yellow opacity-80 cursor-not-allowed"
                : "bg-yellow hover:bg-dark-yellow active:bg-darker-yellow"
                }`}
              disabled={isSubmitting}
            >
              <StyledCtaText
                children={isSubmitting ? "Submitting..." : "Login"}
                fontColor={"text-black font-semibold"}
              />
            </button>
          </div>
        </form>
      </div>
    </AuthenticationLayout>
  );
};

export default LoginSection;
